





























































































import {Component, Vue} from "vue-property-decorator";
import Client from "@/models/Client";
import ClientService from "@/services/ClientService";
import ClientTab from "@/components/tabs/ClientTab.vue";
import AddressTab from "@/components/tabs/AddressTab.vue";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import PromoService from "@/services/PromoService";
import ClientRegisterTab from "@/components/tabs/ClientRegisterTab.vue";
import LangModule from "@/store/LangModule";
import {SingleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";

@Component({components: {ClientTab, AddressTab, ClientRegisterTab}})
export default class ClientView extends Vue {

    lang: any = getModule(LangModule).lang
    loading: boolean = false
    client2: SingleItem<Client> = { item: new Client() }
    tab: number = 0

    async refresh() {
        await Handler.getItem(this, this.client2, () => ClientService.getClient2(Number(this.$route.params.id)))
    }

    deleteClient() {
        getModule(DialogModule).showDialog(new Dialog(
            this.lang.warning, this.lang.deleteClientConfirmation,
            () => ClientService.deleteClient(this, this.client2.item.id!)
        ))
    }

    setEnabled() {
        getModule(DialogModule).showDialog(new Dialog(
            this.lang.warning, "¿Esta seguro de cambiar el estado del cliente?",
            async () => await Handler.getItem(this, this.client2, () =>
                ClientService.setEnabled(this.client2.item.id!, !this.client2.item.enable!)
            )
        ))
    }

    created() {
        this.refresh()
    }

}
